import { Menu } from "@chakra-ui/menu"
import {
  Box,
  Icon,
  IconButton,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  useDisclosure,
  VStack,
} from "@chakra-ui/react"
import NotificationModal from "@components/Header/NotificationModal"
import { DirectusNotification } from "@directus/sdk"
import useNotifications from "@hooks/useNotifications"
import { MDXRemoteSerializeResult } from "next-mdx-remote"
import { Fragment, useEffect, useState } from "react"
import { MdNotifications } from "react-icons/md"
import { mutate } from "swr"

export type NotificationProps = Omit<
  DirectusNotification<object>,
  "message"
> & { message: MDXRemoteSerializeResult }

const NotificationMenu = () => {
  const { data } = useNotifications()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [markAllAsRead, setMarkAllAsRead] = useState<boolean>(false)
  const [notifications, setNotifications] = useState<NotificationProps[]>(
    data?.notifications || []
  )
  const [notification, setNotification] = useState<
    NotificationProps | undefined
  >(undefined)

  const handleMarkAllAsRead = async () => {
    setMarkAllAsRead(true)
    try {
      await fetch("/api/user/notifications/all-read", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          keys: notifications?.map((notification) => notification.id),
        }),
      }).then(() => {
        setMarkAllAsRead(false)
        setNotifications([])
        mutate(`/api/user/notifications`)
      })
    } catch (error) {
      setMarkAllAsRead(false)
      console.error(error)
    }
  }

  useEffect(() => {
    setNotifications(data?.notifications || [])
  }, [data?.notifications])

  return (
    <>
      <Menu>
        <MenuButton
          as={IconButton}
          icon={
            <Box position={"relative"}>
              <Box
                position={"absolute"}
                top={0}
                right={0}
                rounded={"full"}
                w={2}
                h={2}
                bgColor={notifications.length > 0 ? "red.500" : "transparent"}
              />
              <Icon
                as={MdNotifications}
                color={notifications.length > 0 ? "brand.800" : "gray.400"}
                w={6}
                h={6}
              />
            </Box>
          }
          fontWeight={400}
          borderRadius={"none"}
          bgColor={"white"}
        ></MenuButton>
        <MenuList color={"black"}>
          {notifications.map((notification) => (
            <Fragment key={notification.id}>
              <MenuItem
                py={0}
                onClick={() => {
                  setNotification(notification)
                  onOpen()
                }}
              >
                <VStack
                  justifyContent={"start"}
                  alignItems={"start"}
                  w={"full"}
                  spacing={0}
                  p={2}
                >
                  <Box fontSize={"sm"}>{notification.subject}</Box>
                  <Box fontSize={"xs"}>
                    {new Date(`${notification.timestamp}`).toLocaleDateString(
                      "en-AE"
                    )}
                  </Box>
                </VStack>
              </MenuItem>

              <MenuDivider />
            </Fragment>
          ))}

          <MenuItem
            closeOnSelect={false}
            bgColor={"brand.800"}
            color={"white"}
            fontWeight={"500"}
            justifyContent={"center"}
            fontSize={"xs"}
            textTransform={"uppercase"}
            isDisabled={notifications.length === 0 || markAllAsRead}
            onClick={async (e) => {
              e.preventDefault()
              e.stopPropagation()
              await handleMarkAllAsRead()
            }}
          >
            {notifications.length === 0 ? "No notifications" : "Mark as read"}
          </MenuItem>
        </MenuList>
      </Menu>
      <NotificationModal
        isOpen={isOpen}
        onClose={onClose}
        notification={notification}
      />
    </>
  )
}

export default NotificationMenu
