import {
  Box,
  Flex,
  HStack,
  Icon,
  IconButton,
  Link,
  Spinner,
  Stack,
  useDisclosure,
} from "@chakra-ui/react"
import NotificationMenu from "@components/Header/NotificationMenu"
import UserMenu from "@components/Header/UserMenu"
import ButtonLink from "@components/Links/ButtonLink"
import { useStackContext } from "@context/StackContext"
import Logo from "@public/images/logo.svg"
import NextLink from "next/link"
import { useRouter } from "next/router"
import { signOut, useSession } from "next-auth/react"
import React, { ReactNode, useEffect } from "react"
import { BsArrowRight } from "react-icons/bs"
import { GiHamburgerMenu } from "react-icons/gi"
import { IoClose } from "react-icons/io5"

const Links: { name: string; href: string }[] = [
  { name: "My Vessels", href: "/vessels" },
  { name: "My Applications", href: "/applications" },
  {
    name: "Dry Stack Services",
    href: "/drystack-services",
  },
  { name: "Help", href: "/help" },
]

const NavLink = ({
  children,
  href,
  isActive,
}: {
  children: ReactNode
  href: string
  isActive: boolean
}) => {
  return (
    <Link
      as={NextLink}
      px={2}
      py={2}
      _hover={{
        textDecoration: "none",
        color: "brand.300",
      }}
      fontSize={{ base: "md", lg: "lg" }}
      href={href}
      color={isActive ? "brand.300" : "white"}
      borderBottom={isActive ? "1px solid white" : "none"}
    >
      {children}
    </Link>
  )
}

const Header = () => {
  const { pathname, query } = useRouter()
  const { data: session, status } = useSession()
  const { updateStackData } = useStackContext()

  const currentPath = pathname !== "/[slug]" ? pathname : `/${query?.slug}`

  useEffect(() => {
    // Sign out user on error, for more info:
    // https://next-auth.js.org/v3/tutorials/refresh-token-rotation#client-side
    // Using this client-side catch handler in Header as it's on every page
    if (session?.error === "RefreshAccessTokenError") {
      updateStackData({ berth: null })
      signOut({ callbackUrl: "/" })
    }
  }, [session, updateStackData])

  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Box w={"full"}>
      <Flex
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <HStack
          as={"nav"}
          spacing={8}
          alignItems={"center"}
        >
          <Link
            as={NextLink}
            href={"/"}
            w={16}
            h={16}
          >
            <Icon
              as={Logo}
              w={16}
              h={16}
            />
          </Link>
          <HStack
            as={"nav"}
            spacing={4}
            display={{ base: "none", lg: "flex" }}
          >
            {Links.map((link, index) => (
              <NavLink
                key={index}
                href={link.href}
                isActive={currentPath === link.href}
              >
                {link.name}
              </NavLink>
            ))}
          </HStack>
        </HStack>
        <Flex alignItems={"center"}>
          {status === "loading" ? (
            <Spinner
              thickness={"4px"}
              speed={"0.65s"}
              emptyColor={"gray.200"}
              color={"brand.500"}
            />
          ) : status === "authenticated" ? (
            <HStack spacing={4}>
              <NotificationMenu />
              <UserMenu session={session} />
            </HStack>
          ) : (
            <ButtonLink
              href={"/signin"}
              justifyContent={"space-between"}
              rightIcon={
                <Icon
                  as={BsArrowRight}
                  w={5}
                  h={5}
                />
              }
            >
              {"Sign In"}
            </ButtonLink>
          )}

          <IconButton
            icon={<Icon as={isOpen ? IoClose : GiHamburgerMenu} />}
            aria-label={"Open Menu"}
            display={{ lg: "none" }}
            onClick={isOpen ? onClose : onOpen}
            variant={"ghost"}
            color={"white"}
            _hover={{
              bg: "transparent",
            }}
          />
        </Flex>
      </Flex>

      {isOpen ? (
        <Box
          pt={10}
          pb={4}
          display={{ lg: "none" }}
        >
          <Stack
            as={"nav"}
            spacing={4}
          >
            {Links.map((link, index) => (
              <NavLink
                key={index}
                href={link.href}
                isActive={currentPath === link.href}
              >
                {link.name}
              </NavLink>
            ))}
          </Stack>
        </Box>
      ) : null}
    </Box>
  )
}

export default Header
