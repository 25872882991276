import { NotificationProps } from "@components/Header/NotificationMenu"
import useSWR, { Fetcher } from "swr"

const UserFetcher: Fetcher<{
  notifications: NotificationProps[]
}> = async () =>
  await fetch("/api/user/notifications").then((res) => res.json())

const useNotifications = () => {
  const { data, error, isLoading } = useSWR<{
    notifications: NotificationProps[]
  }>("/api/user/notifications", UserFetcher)

  return {
    data: data,
    isLoading: isLoading,
    isError: error,
  }
}

export default useNotifications
