import type { ButtonProps } from "@chakra-ui/react"
import { Button, Icon } from "@chakra-ui/react"
import { BsArrowRight } from "react-icons/bs"

const BrandButton = ({ children, ...props }: ButtonProps) => {
  return (
    <Button
      w={"full"}
      variant={"solid"}
      bgColor={"brand.800"}
      color={"white"}
      fontWeight={"400"}
      fontSize={"xl"}
      py={6}
      borderRadius={"none"}
      rightIcon={
        <Icon
          as={BsArrowRight}
          w={5}
          h={5}
        />
      }
      _hover={{
        bgColor: "brand.300",
      }}
      {...props}
    >
      {children}
    </Button>
  )
}

export default BrandButton
