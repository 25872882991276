import {
  Box,
  Container,
  Flex,
  Grid,
  GridItem,
  GridProps,
  Spinner,
} from "@chakra-ui/react"
import Footer from "@components/Footer"
import Header from "@components/Header"
import { useSession } from "next-auth/react"

const Layout = ({
  children,
  dark,
  fullWidth,
  coverImage,
  ...props
}: {
  dark?: boolean
  fullWidth?: boolean
  coverImage?: string
} & GridProps) => {
  const { status } = useSession()

  return (
    <>
      {coverImage && (
        <Box
          bgImage={`${process.env.NEXT_PUBLIC_CDN_URL}/5e239b99-a8bf-4b2e-abc1-4600414896e4.jpg?key=large`}
          bgPosition={"center"}
          bgRepeat={"no-repeat"}
          bgSize={"cover"}
          position={"absolute"}
          left={0}
          right={0}
          top={0}
          bottom={0}
          zIndex={0}
          height={"100vh"}
        />
      )}
      <Grid
        templateAreas={`"header"
                  "main"
                  "footer"`}
        gridTemplateRows={"1fr"}
        gridTemplateColumns={"1fr"}
        overflowX={"hidden"}
        bgColor={coverImage ? "transparent" : dark ? "brand.800" : "brand.50"}
        color={dark ? "white" : "brand.800"}
        position={"relative"}
        {...props}
      >
        <GridItem
          area={"header"}
          as={"header"}
          bgColor={"brand.800"}
          color={"white"}
          paddingX={{ base: 6, md: 12 }}
          paddingY={6}
          m={{ base: 0, md: 5 }}
          transition={"all 0.7s ease"}
          borderWidth={1}
          borderColor={"brand.50"}
        >
          <Header />
        </GridItem>
        <GridItem
          area={"main"}
          as={"main"}
          overflowX={"hidden"}
          minH={"80vh"}
        >
          {status === "loading" ? (
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              h={"full"}
            >
              <Spinner
                thickness={"5px"}
                speed={"0.65s"}
                emptyColor={"gray.200"}
                color={"brand.800"}
                size={"xl"}
              />
            </Flex>
          ) : fullWidth ? (
            <Box
              w={"full"}
              pt={6}
            >
              {children}
            </Box>
          ) : (
            <Container
              maxW={"container.xl"}
              pt={6}
              pb={10}
            >
              {children}
            </Container>
          )}
        </GridItem>
        <GridItem
          area={"footer"}
          as={"footer"}
          bgColor={"brand.800"}
        >
          <Footer />
        </GridItem>
      </Grid>
    </>
  )
}

export default Layout
