import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react"
import { NotificationProps } from "@components/Header/NotificationMenu"
import BrandButton from "@components/Links/BrandButton"
import { MDXRemote } from "next-mdx-remote"
import { useState } from "react"
import { mutate } from "swr"

type NotificationModalProps = {
  isOpen: boolean
  onClose: () => void
  notification?: NotificationProps
}

const NotificationModal = ({
  isOpen,
  onClose,
  notification,
}: NotificationModalProps) => {
  const [submitting, setSubmitting] = useState<boolean>(false)
  const handleMarkAsRead = async () => {
    setSubmitting(true)
    try {
      await fetch(`/api/user/notifications/read/${notification?.id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          keys: [notification?.id],
        }),
      }).then(() => {
        mutate(`/api/user/notifications`)
        setSubmitting(false)
        onClose()
      })
    } catch (error) {
      setSubmitting(false)
      console.error(error)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={"2xl"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{notification?.subject}</ModalHeader>
        <ModalCloseButton />
        <ModalBody
          pt={6}
          pb={10}
          bgColor={"brand.50"}
        >
          {notification && <MDXRemote {...notification?.message} />}
        </ModalBody>
        <ModalFooter>
          <BrandButton
            onClick={handleMarkAsRead}
            isLoading={submitting}
          >
            {"Mark as read"}
          </BrandButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default NotificationModal
