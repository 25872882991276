import { Button, ButtonProps, Link } from "@chakra-ui/react"
import NextLink from "next/link"

interface ButtonLinkProps extends ButtonProps {
  href: string
}

const ButtonLink = ({ href, children, ...props }: ButtonLinkProps) => {
  return (
    <NextLink
      href={href}
      passHref
      legacyBehavior
    >
      <Button
        as={Link}
        rounded={"none"}
        minW={36}
        h={9}
        fontWeight={400}
        color={"brand.800"}
        bgColor={"white"}
        _hover={{
          textDecoration: "none",
          bgColor: "brand.300",
          color: "white",
        }}
        {...props}
      >
        {children}
      </Button>
    </NextLink>
  )
}

export default ButtonLink
