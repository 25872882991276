import {
  Box,
  Center,
  Container,
  Divider,
  Flex,
  HStack,
  Link,
} from "@chakra-ui/react"

const Footer = () => {
  return (
    <Box w={"full"}>
      <Container
        maxW={"container.xl"}
        px={10}
      >
        <Flex
          flexWrap={"wrap"}
          justifyContent={{ base: "center", md: "space-between" }}
          direction={{ base: "column", md: "row" }}
          alignItems={"center"}
          paddingY={6}
          color={"whiteAlpha.700"}
          fontSize={"sm"}
        >
          <Box>
            &copy; {new Date().getFullYear()}
            {" Aldar. All Rights Reserved"}
          </Box>
          <HStack
            alignItems={"center"}
            spacing={{ base: 2, md: 4 }}
          >
            <Link
              href={"https://aldarmarinas.com/terms-conditions"}
              _hover={{ textDecoration: "none", color: "whiteAlpha.500" }}
              isExternal
            >
              {"Terms & Conditions"}
            </Link>
            <Center height={3}>
              <Divider orientation={"vertical"} />
            </Center>
            <Link
              href={"https://aldarmarinas.com/privacy-policy"}
              _hover={{ textDecoration: "none", color: "whiteAlpha.500" }}
              isExternal
            >
              {"Privacy Policy"}
            </Link>
          </HStack>
        </Flex>
      </Container>
    </Box>
  )
}

export default Footer
