import { Menu } from "@chakra-ui/menu"
import {
  Button,
  Icon,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react"
import { useStackContext } from "@context/StackContext"
import NextLink from "next/link"
import type { Session } from "next-auth"
import { signOut } from "next-auth/react"
import { IoCaretDown, IoCaretUp } from "react-icons/io5"

const UserMenu = ({ session }: { session: Session }) => {
  const { updateStackData } = useStackContext()
  const logoutHandler = () => {
    updateStackData({ berth: null })
    signOut({ callbackUrl: "/" })
  }

  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton
            as={Button}
            rightIcon={
              <Icon
                as={isOpen ? IoCaretDown : IoCaretUp}
                w={4}
                h={4}
              />
            }
            fontWeight={400}
            borderRadius={"none"}
            bgColor={"white"}
            maxW={{ base: 36, md: 56 }}
          >
            <Text
              isTruncated
            >{`${session.user?.first_name} ${session.user?.last_name}`}</Text>
          </MenuButton>
          <MenuList color={"black"}>
            <MenuItem
              as={NextLink}
              href={"/profile"}
            >
              {"My Profile"}
            </MenuItem>
            <MenuItem
              as={NextLink}
              href={"/vessels"}
            >
              {"My Vessels"}
            </MenuItem>
            <MenuItem
              as={NextLink}
              href={"/applications"}
            >
              {"My Applications"}
            </MenuItem>
            <MenuItem onClick={logoutHandler}>{"Sign Out"}</MenuItem>
          </MenuList>
        </>
      )}
    </Menu>
  )
}

export default UserMenu
